<template>
    <div class="bg-white flex-fill text-navy d-flex align-items-center justify-content-center"
        style="background-color: #fff; height: 100dvh; background-image: url('/static/image/slider/5.jpg'); background-size: cover;">
        <el-card>
            <div class="text-center">
                <div class="mt-3">
                    <img src="/img/auth-callback-indicator.jpg" 
                        style="width: 100%; max-width: 30rem; min-width: 400px;" />
                </div>
                <div class="mt-3 d-flex align-items-center justify-content-center">
                    <div>
                        <div class="lds-loading"><div></div><div></div><div></div></div>
                    </div>
                    <h4 class="text-left ml-3">
                        Đang xử lý đăng nhập thành công...
                    </h4>
                </div>
            </div>
        </el-card>
    </div>
</template>

<script>
    import { securityHelper } from '@/helpers/securityHelper.js';
    export default {
        mounted() { 
            //const accessToken = localStorage.getItem(securityHelper.getAccessTokenStoreName());
            securityHelper.signinRedirectCallback()
                .then((user) => {
                    localStorage.setItem(securityHelper.getAccessTokenStoreName(), user.access_token);
                    localStorage.setItem('_isLogined', "true");   
                    localStorage.setItem("user", JSON.stringify({
                        ...user.profile,
                        roleId: user.profile.roleId !== null ? parseInt(user.profile.roleId) : null,
                        isDefaultPassword: user.profile.isDefaultPassword === "true",
                        isIpWhiteListApply: user.profile.isIpWhiteListApply === "true",
                        joinTheShift: user.profile.joinTheShift === "true",
                        shiftOrdered: user.profile.shiftOrdered === "true",
                        twoFactorEnabled: user.profile.twoFactorEnabled === "true",
                    }));  
                    securityHelper.goToHomePage();                      
                }).catch((e) => {
                    console.error(e);
                    localStorage.removeItem('_isLogined');
                    securityHelper.goToLoginPage(); 
                });
        }
    }
</script>